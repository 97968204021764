/* 스마트티켓 알아보기 */
.smart_ticket_info .section {margin:0;border:0;padding:58px 20px 0;box-sizing:border-box}
.smart_ticket_info .section h2 {font-weight:500;font-size:16px;line-height:24px;letter-spacing:-0.08em}
.smart_ticket_info .section .step {display:flex;flex-direction:column;gap:16px 0;counter-reset:n 0}
.smart_ticket_info .section .step li {display:flex;position:relative;min-height:122px;border-radius:12px;color:#62676C;font-weight:400;font-size:18px;line-height:26px;letter-spacing:-0.04em;background:#ECEDF2}
.smart_ticket_info .section .step li .img {flex:1;margin-top:auto;width:60%;height:122px;background-position:50% 0;background-repeat:no-repeat;background-size:170px auto}
.smart_ticket_info .section .step li .txt {display:flex;flex-direction:column;justify-content:center;flex:0 0 auto;padding:10px 20px 10px 0;width:40%;box-sizing:border-box;font-size:12px;line-height:16px}
.smart_ticket_info .section .step li .txt:before {flex:0 0 auto;margin:0 auto 7px 0;padding:0 8px;height:18px;border-radius:35px;color:#fff;font-size:12px;line-height:18px;letter-spacing:-0.06em;background:#FA2828;counter-increment:n 1;content:'STEP' counter(n)}
.smart_ticket_info .sec1 {position:relative;text-align:center}
.smart_ticket_info .sec1 h2 {font-weight:700}
.smart_ticket_info .sec1 .box {overflow:hidden;margin:32px auto 0;padding-bottom:20px;border-radius:16px;box-shadow:0 0 40px rgba(236, 237, 242, 1)}
.smart_ticket_info .sec1 .box .img {display:block;height:296px;background:#ECEDF2 url(../../../web/img/smart/m_img_sec1.png) 50% 130% no-repeat;background-size:178px auto}
.smart_ticket_info .sec1 .txt {font-weight:500;font-size:13px;line-height:18px;letter-spacing:-0.03em}
.smart_ticket_info .sec1 .txt:nth-of-type(1) {margin-top:20px;color:#242428;font-weight:500}
.smart_ticket_info .sec1 .txt:nth-of-type(1)>em {display:block;color:#FA2828;font-weight:700;font-size:18px;line-height:26px;letter-spacing:-0.04em}
.smart_ticket_info .sec1 .txt:nth-of-type(2) {margin-top:6px;color:#878D95;font-weight:400}
.smart_ticket_info .sec2 {padding-top:48px}
.smart_ticket_info .sec2 h2 {font-size:14px;line-height:20px;letter-spacing:-0.03em}
.smart_ticket_info .sec2 p {margin-top:4px;color:#62676C;font-size:13px;line-height:18px;letter-spacing:-0.03em}
.smart_ticket_info .sec3 .step {margin-top:22px}
.smart_ticket_info .sec3 .step li:nth-child(1) .img {background-image:url(../../../web/img/smart/m_img_sec3_1.png)}
.smart_ticket_info .sec3 .step li:nth-child(2) .img {background-image:url(../../../web/img/smart/m_img_sec3_2.png)}
.smart_ticket_info .sec3 .step li:nth-child(3) .img {height:168px;background-image:url(../../../web/img/smart/m_img_sec3_3.png)}
.smart_ticket_info .sec4 {padding-bottom:60px}
.smart_ticket_info .sec4 .step {margin-top:22px}
.smart_ticket_info .sec4 .step li:nth-child(1) .img {background-image:url(../../../web/img/smart/m_img_sec4_1.png)}
.smart_ticket_info .sec4 .step li:nth-child(2) .img {background-image:url(../../../web/img/smart/m_img_sec4_2.png)}
.smart_ticket_info .sec4 .step li:nth-child(3) .img {background-image:url(../../../web/img/smart/m_img_sec4_3.png)}
.smart_ticket_info .sec5 {padding:20px;background:#F8F9FA}
.smart_ticket_info .sec5 ul {display:flex;flex-direction:column;gap:5px 0;margin-top:10px}
.smart_ticket_info .sec5 ul li {position:relative;padding-left:10px;color:#62676C;font-weight:400;font-size:12px;line-height:16px;letter-spacing:-0.03em}
.smart_ticket_info .sec5 ul li:before {position:absolute;top:8px;left:0;width:2px;height:2px;border-radius:4px;background:#62676C;content:""}
.smart_ticket_info .sec1 .box .img.en {background-image:url(../../../web/img/smart/m_img_sec1_en.png)}
.smart_ticket_info .sec3 .step li:nth-child(1) .img.en {background-image:url(../../../web/img/smart/m_img_sec3_1_en.png)}
.smart_ticket_info .sec3 .step li:nth-child(2) .img.en {background-image:url(../../../web/img/smart/m_img_sec3_2_en.png)}
.smart_ticket_info .sec3 .step li:nth-child(3) .img.en {background-image:url(../../../web/img/smart/m_img_sec3_3_en.png)}
.smart_ticket_info .sec4 .step li:nth-child(1) .img.en {background-image:url(../../../web/img/smart/m_img_sec4_1_en.png)}
.smart_ticket_info .sec4 .step li:nth-child(2) .img.en {background-image:url(../../../web/img/smart/m_img_sec4_2_en.png)}